







import Vue from "vue";

import Component from "vue-class-component";

@Component({
  name: "IconWarning",
})
export default class IconWarning extends Vue {
}
