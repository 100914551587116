





















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import Component from "vue-class-component";
import { getModule } from "vuex-module-decorators";
import { Watch } from "vue-property-decorator";
import Debounce from "lodash/debounce";
const { v4: uuidv4 } = require("uuid");

import BaseComponent from "@/components/base-component.vue";
import PhotoPreview from "@/components/photo-preview/photo-preview.vue";
import PhotoFrame from "@/components/photo-frame/photo-frame.vue";
import Loader from "@/components/loader.vue";
import PaymentDiscount from '@/components/payment-discount/payment-discount.vue';
import Dropdown from '@/components/dropdown/dropdown.vue';
import BaseIcon from "@/components/base-icon/base-icon.vue";
import BaseIllustration from "@/components/base-illustration/base-illustration.vue";
import IconCheckCircle from "@/components/icons/icon-check-circle.vue";
import IconRight from "@/components/icons/icon-right.vue";
import IconRight2 from "@/components/icons/icon-right2.vue";
import IconDelete from "@/components/icons/icon-delete.vue";
import IconDelete2 from "@/components/icons/icon-delete2.vue";
import IconCheckCircleS from "@/components/icons/icon-check-circle-s.vue";
import IconWarning from "@/components/icons/icon-warning.vue";
import IconLeft from "@/components/icons/icon-left.vue";
import IconLeft2 from "@/components/icons/icon-left2.vue";
import EmptyRafiki from "@/components/illustrations/empty-rafiki.vue";
import { CartState } from "@/store/modules/cart";
import { EventsState } from "@/store/modules/events";
import { SearchState } from "@/store/modules/search";
import { CatalogState } from "@/store/modules/catalog";
import { SettingsState } from "@/store/modules/settings";
import { ProductTypes } from "@/product-types";
import { PromocodeErrors } from "@/promocode-errors";
import { OfferStates } from "@/offer-states";
import { SBP } from "@/SBP";
import Common from "@/plugins/common";

@Component({
  name: "Cart",
  components: {
    PhotoPreview,
    PhotoFrame,
    Loader,
    PaymentDiscount,
    Dropdown,
    BaseIcon,
    BaseIllustration,
    IconCheckCircle,
    IconRight,
    IconRight2,
    IconDelete,
    IconDelete2,
    IconCheckCircleS,
    IconWarning,
    IconLeft,
    IconLeft2,
    EmptyRafiki,
  },
})
export default class Cart extends BaseComponent {

  private readonly eventsState: EventsState = getModule(EventsState, this.$store);
  private readonly cartState: CartState = getModule(CartState, this.$store);
  private readonly searchState: SearchState = getModule(SearchState, this.$store);
  private readonly catalogState: CatalogState = getModule(CatalogState, this.$store);
  private readonly settingsState: SettingsState = getModule(SettingsState, this.$store);

  productTypes: any = ProductTypes;
  products: any[] = [];

  byNumber_: any[] = [];
  byFace_: any[] = [];
  
  promocodeItems: any[] = [];
  debouncedUpdatePromocodes: any = null;

  photosDialog: boolean = false;
  photos: any[] = [];
  photosIndex: number = 0;
  
  promocode: string = "";
  promocodeError: string = "";

  decodedFiles: File[] = [];

  byNumberRemoved: any[] = [];
  byFaceRemoved: any[] = [];

  reloadCount: number = 0;
  max_reload_count: number = 30;

  loading: boolean = true;
  isReloading: boolean = false;

  frameProducts: any[] = [];
  photoFrameDialog: boolean = false;
  photoFrameProps: any = {};
  photoFramesInCart: any[] = [];
  photoFrameGift: any = null;
  runnerFrameValues: any = null;
  isAddingPhotosWithMe: boolean = false;
  isAddingPhotosWithFrame: boolean = false;
  isAddingFrame: boolean = false;

  get theme(): any {
    return this.settingsState.theme;
  }

  get tenantName(): any {
    return this.settingsState.tenantName;
  }

  get isMySportUSA(): boolean {
    return this.settingsState.isMySportUSA === true;
  }

  get cart(): any {
    return this.cartState.cart;
  }

  get cartItemsCount(): number {
    const allPhotos: any[] = this.getCartItems(this.productTypes.photos_with_me);
    const singlePhotos: any[] = this.getCartItems(this.productTypes.photo);
    const frames: any[] = this.getCartItems(this.productTypes.frame);
    const photosWithFrame: any[] = this.getCartItems(this.productTypes.photosWithFrame);
    let promocodes: any[] = this.getCartItems(this.productTypes.promocode);
    promocodes = this.getGroupedByEvent(promocodes);
    return allPhotos.length + singlePhotos.length + frames.length + photosWithFrame.length + promocodes.length;
  }

  get cartAmount(): number {
    if (this.cartState.cart === null) return 0;
    return this.cartState.cart.amount;
  }

  get cartTotalAmount(): number {
    if (this.cartState.cart === null) return 0;
    return this.cartState.cart.totalAmount;
  }
  
  get promocodes(): any[] {
    return this.cartState.cart.promotionalCodes;
  }

  get byNumber(): any[] {
    return this.byNumber_;
  }

  get byFace(): any[] {
    return this.byFace_;
  }

  get lang(): string {
    return this.settingsState.lang;
  }

  get paymentDiscountItems(): any[] {
    const items = [
      { img: '/img/sbp/short-sbp.svg', text: this.$t('paymentDiscount/sbp'.toString()) },
    ];

    return this.showSbp ? items : [];
  }

  get showSbp(): boolean {
    if (!this.cartState.cart) return false;
    if (!this.cartState.cart.paymentTypes) return false;
    const index = this.cartState.cart.paymentTypes.findIndex((r: any) => r.paymentTypeId == "QuickPayment");
    return index >= 0 && this.tenantName !== 'Sporttiming' && SBP.enabled === true;
  }

  get showPaymentDiscount(): boolean {
    return this.theme === '' && this.tenantName !== 'Sporttiming' && !this.isMySportUSA && this.paymentDiscountItems.length > 0 && SBP.enabled === true;
  }

  get displayDiscountDetails(): boolean {
    return this.theme === '' && this.lang === 'ru';
  }

  get photosPerPage(): number {
    if (this.smOnly || this.mdOnly) return 12;
    return 50;
  }

  get isUpdating(): boolean {
    return this.isAddingPhotosWithMe || this.isAddingPhotosWithFrame || this.isAddingFrame;
  }

  formatAmount(amount: number, onlyInteger: boolean = true): string {
    return Common.formatAmount(amount, onlyInteger);
  }

  roundToPrecision(value: number, precision: number = 2): number {
    if (precision < 0) return value;
    if (precision === 0) return Math.round(value);
    return parseFloat((Math.round(value * Math.pow(10, precision + 1)) * Math.pow(10, -(precision + 1))).toFixed(precision))
  }

  getDiscount(amount: number, totalAmount: number): number {
    const discount: number = amount - totalAmount;

    return this.roundToPrecision(discount);
  }

  getDiscountDetailsText(): string {
    const {
      discountPackage,
      discountPromocode,
    } = this.getDiscountFractions();

    const detailsText: string[] = [];
    if (discountPackage > 0) {
      detailsText.push(`${this.$t('cart/discountDetailsPackage')} — ${this.formatAmount(discountPackage, false)}`);
    }
    if (discountPromocode > 0) {
      detailsText.push(`${this.$t('cart/discountDetailsPromocode')} — ${this.formatAmount(discountPromocode, false)}`);
    }
    return detailsText.join('<br>');
  }

  getDiscountFractions() {
    const fractions = {
      discountPackage: 0,
      discountPromocode: 0,
    }

    const items: any[] = this.cartState.cartItems;
    for (let i = 0; i < items.length; i += 1) {
      const item: any = items[i];
      let discountPromocode: number = 0;
      let discountPackage: number = 0;
      if (item.promotionalCode) {
        const discount = item.promotionalCode.discount;
        if (discount.unit === 'Percentage') {
          discountPromocode = this.roundToPrecision(item.originalPrice * ((discount.value || 0) / 100));
        }
        if (discount.unit == 'Fixed') {
          discountPromocode = this.roundToPrecision(discount.value);
        }
      }
      if (discountPromocode === 0 && (item.totalPrice < item.originalPrice)) {
        discountPackage = this.roundToPrecision(item.originalPrice - item.totalPrice);
      }
      if (discountPromocode > 0 && (item.price < item.originalPrice)) {
        discountPackage = this.roundToPrecision((item.originalPrice - discountPromocode) - item.totalPrice);
      }
      fractions.discountPackage = this.roundToPrecision(fractions.discountPackage + discountPackage);
      fractions.discountPromocode = this.roundToPrecision(fractions.discountPromocode + discountPromocode);
    }

    return fractions;
  }

  getEventIds(): string[] {
    if (this.products.length == 0) return [];

    const events: string[] = [];
    for (let i = 0; i < this.products.length; i++) {
      if (events.length == 0) {
        events.push(this.products[i].catalog.catalogId);
        continue;
      }

      if (!events.includes(this.products[i].catalog.catalogId)) {
        events.push(this.products[i].catalog.catalogId);
      }
    }
    
    return events;
  }

  isDiscountEnabled(item: any): boolean {
    if (this.products.length === 0) return false;
    
    const events: any[] = this.getEventIds();
    if (events.length === 0) return false;

    for (let i = 0; i < this.products.length; i++) {
      if (this.products[i].catalog.catalogId !== item.eventId) continue;

      const items: any[] = this.getCartItemsWithProductId(this.products[i].productId);
      if (items.length === 0) continue;

      const rule: any = this.products[i].priceRule;
      if (!rule) continue;
      if (items.length > rule.minQuantity) continue;
      
      let photos: any[] = this.getCartItemsOfType(this.productTypes.photos_with_me);
      photos = photos.filter((r: any) => r.catalog.catalogId === item.eventId);
      if (this.products[i].productType === this.productTypes.photo && photos.length > 0) {
        continue;
      }

      if (this.products[i].productType === this.productTypes.promocode) {
        return true;
      }
      
      return true;
    }

    return false;
  }

  getDiscountRange(eventId: string): string {
    if (this.products.length === 0) return "";
    
    for (let i = 0; i < this.products.length; i++) {
      if (this.products[i].catalog.catalogId !== eventId) continue;

      const items: any[] = this.getCartItemsWithProductId(this.products[i].productId);
      if (items.length === 0) continue;

      const rule: any = this.products[i].priceRule;
      if (!rule) continue;
      if (items.length > rule.minQuantity) continue;

      return `${rule.minQuantity + 1}–${rule.maxQuantity}`;
    }

    return "";
  }

  getDiscountText(eventId: string): string {
    if (this.products.length === 0) return "";
    
    for (let i = 0; i < this.products.length; i++) {
      if (this.products[i].catalog.catalogId !== eventId) continue;

      const items: any[] = this.getCartItemsWithProductId(this.products[i].productId);
      if (items.length === 0) continue;

      const rule: any = this.products[i].priceRule;
      if (!rule) continue;
      if (items.length > rule.minQuantity) continue;

      const newPrice = this.formatAmount(this.getDiscountAmount(this.products[i].amount, rule), false);
      const oldPrice = this.formatAmount(this.products[i].amount);
      return this.$t('cart/specialDiscount', [newPrice, oldPrice]).toString();
    }

    return "";
  }

  getDiscountLinkText(eventId: string): string {
    const add = this.$t('packageOffers/add').toString();
    const product = this.getDiscountProduct(eventId);
    if (product && product.title) {
      const title = product.productType === this.productTypes.frame ? this.$t('searchResults/productPhotoFrame') : product.title;
      return `${add} ${title}`;
    }

    return '';
  }

  getDiscountProductType(eventId: string): string {
    if (this.products.length == 0) return "";
    
    for (let i = 0; i < this.products.length; i++) {
      if (this.products[i].catalog.catalogId !== eventId) continue;

      const items: any[] = this.getCartItemsWithProductId(this.products[i].productId);
      if (items.length == 0) continue;

      const rule: any = this.products[i].priceRule;
      if (!rule) continue;
      if (items.length > rule.minQuantity) continue;

      return items[0].product.productType;
    }

    return "";
  }

  getDiscountProductName(eventId: string): string {
    if (this.products.length === 0) return "";
    
    for (let i = 0; i < this.products.length; i++) {
      if (this.products[i].catalog.catalogId !== eventId) continue;

      const items: any[] = this.getCartItemsWithProductId(this.products[i].productId);
      if (items.length === 0) continue;

      const rule: any = this.products[i].priceRule;
      if (!rule) continue;
      if (items.length > rule.minQuantity) continue;

      return this.getItemTitle(items[0]);
    }

    return "";
  }

  getDiscountProduct(eventId: string): any {
    if (this.products.length === 0) return "";
    
    for (let i = 0; i < this.products.length; i++) {
      if (this.products[i].catalog.catalogId !== eventId) continue;

      const items: any[] = this.getCartItemsWithProductId(this.products[i].productId);
      if (items.length === 0) continue;

      const rule: any = this.products[i].priceRule;
      if (!rule) continue;
      if (items.length > rule.minQuantity) continue;

      return this.products[i];
    }

    return "";
  }

  getItemTitle(item: any): string {
    const type: string = item.product.productType;
    if (type.length == 0) return "";

    if (type == this.productTypes.photos_with_me) {
      return this.$t('productPreview/productAllPhotos').toString();
    } else if (type == this.productTypes.photosWithFrame) {
      return item.product.title;
    } else if (type == this.productTypes.photo) {
      return this.$t('productPreview/productDigitalPhoto').toString();
    } else if (type == this.productTypes.promocode) {
      return item.product.title;
    } else {
      return this.$t('searchResults/productPhotoFrame').toString();
    }
  }

  getDiscountAmount(price: number, rule: any): number {
    if (rule.discount.unit === "Percentage") {
      return price - (price * (rule.discount.value / 100));
    } else if (rule.discount.unit === "Fixed") {
      return price - rule.discount.value;
    }
    return 0;
  }

  hasPackageOffers(item: any): boolean {
    if (this.products.length === 0) return false;
    
    const events: any[] = this.getEventIds();
    if (events.length === 0) return false;

    for (let i = 0; i < this.products.length; i += 1) {
      if (this.products[i].catalog.catalogId !== item.eventId) continue;

      const items: any[] = this.getCartItemsWithProductId(this.products[i].productId);
      if (items.length === 0) continue;

      const offers = [...this.products[i].packageOffers];
      if (offers.length === 0) continue;

      for (let k = 0; k < offers.length; k += 1) {
        if (offers[k].dependencies.length > 0) continue;
        if (items.length < offers[k].size) return true;
      }
    }

    return false;
  }

  hasGift(product: any): boolean {
    if (!product) return false;
    const found: any[] = this.products.filter((r: any) => r.productId === product.product.productId);
    if (found.length === 0) return false;

    const item = this.getGiftForProduct(found[0]);
    if (item) {
      const gift = this.getGiftItem({ ...item, trigger: { ...found[0] } });
      if (gift && gift.state === OfferStates.giftReady) return true;
    }
    return false;
  }

  getGift(product: any): any {
    if (!product) return null;
    const found: any[] = this.products.filter((r: any) => r.productId === product.product.productId);
    if (found.length === 0) return null;

    const item = this.getGiftForProduct(found[0]);
    if (item) {
      const gift = this.getGiftItem({ ...item, trigger: { ...found[0] } });
      if (gift && gift.state === OfferStates.giftReady) return { ...item, trigger: { ...found[0] } };
    }
    return null;
  }

  getGiftForProduct(product: any): any {
    if (!product) return null;
    const candidates: any[] = [...product.candidates];
    for (let i = 0; i < candidates.length; i += 1) {
      const gifts: any[] = candidates[i].packageOffers.filter((r: any) => r.price === 0);
      if (gifts.length > 0) {
        const quantity = gifts[0].dependencies[0] ? gifts[0].dependencies[0].quantity : 0;
        if (quantity) {
          return { product: { ...candidates[i] }, quantity, size: gifts[0].size };
        }
        return null;
      }
    }
    return null;
  }

  getGiftItem(gift: any): any {
    if (!gift) return null;

    const productCount = this.getCartItemsWithProductId(gift.product.productId).length;
    const triggerCount = this.getCartItemsWithProductId(gift.trigger.productId).length;
    let state = OfferStates.initial;
    if (productCount >= gift.size && triggerCount >= gift.quantity) state = OfferStates.inCart;
    if (productCount < gift.size && triggerCount >= gift.quantity) state = OfferStates.giftReady;

    const title = gift.product.productType === this.productTypes.frame ? this.$t('searchResults/productPhotoFrame').toString() : gift.product.title;
    const props: any = {
      state,
      text: this.$t('cart/productAsGift', [title, gift.size]).toString(),
    };
    return props;
  }

  getGiftText(product: any): string {
    if (!product) return '';
    const found: any[] = this.products.filter((r: any) => r.productId === product.product.productId);
    if (found.length === 0) return '';

    const item = this.getGiftForProduct(found[0]);
    if (item) {
      const gift = this.getGiftItem({ ...item, trigger: { ...found[0] } });
      if (gift && gift.state === OfferStates.giftReady) return gift.text;
    }
    return '';
  }

  getOfferSize(item: any): number {
    if (this.products.length === 0) return 0;
    
    const events: any[] = this.getEventIds();
    if (events.length === 0) return 0;

    for (let i = 0; i < this.products.length; i += 1) {
      if (this.products[i].catalog.catalogId !== item.eventId) continue;

      const items: any[] = this.getCartItemsWithProductId(this.products[i].productId);
      if (items.length === 0) continue;

      const offers = [...this.products[i].packageOffers];
      if (offers.length === 0) continue;

      for (let k = 0; k < offers.length; k += 1) {
        if (offers[k].dependencies.length > 0) continue;
        if (items.length < offers[k].size) return offers[k].size - items.length;
      }
    }

    return 0;
  }

  getOfferProductName(item: any): string {
    if (this.products.length === 0) return '';
    
    const events: any[] = this.getEventIds();
    if (events.length === 0) return '';

    for (let i = 0; i < this.products.length; i += 1) {
      if (this.products[i].catalog.catalogId !== item.eventId) continue;

      const items: any[] = this.getCartItemsWithProductId(this.products[i].productId);
      if (items.length === 0) continue;

      const offers = [...this.products[i].packageOffers];
      if (offers.length === 0) continue;

      for (let k = 0; k < offers.length; k += 1) {
        if (offers[k].dependencies.length > 0) continue;
        if (items.length < offers[k].size) {
          const title = this.products[i].productType === this.productTypes.frame ? this.$t('searchResults/productPhotoFrame').toString() : this.products[i].title;
          return title;
        }
      }
    }

    return '';
  }

  getOfferText(item: any): string {
    if (this.products.length === 0) return '';
    
    const events: any[] = this.getEventIds();
    if (events.length === 0) return '';

    for (let i = 0; i < this.products.length; i += 1) {
      if (this.products[i].catalog.catalogId !== item.eventId) continue;

      const items: any[] = this.getCartItemsWithProductId(this.products[i].productId);
      if (items.length === 0) continue;

      const offers = [...this.products[i].packageOffers];
      if (offers.length === 0) continue;

      for (let k = 0; k < offers.length; k += 1) {
        if (offers[k].dependencies.length > 0) continue;
        if (items.length < offers[k].size) {
          const newPrice = this.formatAmount(offers[k].price / offers[k].size, false);
          const oldPrice = this.formatAmount(this.products[i].amount);
          const title = this.products[i].productType === this.productTypes.frame ? this.$t('searchResults/productPhotoFrame').toString() : this.products[i].title;
          return this.$t('cart/newPrice', [title, newPrice, oldPrice]).toString();
        }
      }
    }

    return '';
  }

  getOfferProduct(item: any): any {
    if (this.products.length === 0) return null;
    
    const events: any[] = this.getEventIds();
    if (events.length === 0) return null;

    for (let i = 0; i < this.products.length; i += 1) {
      if (this.products[i].catalog.catalogId !== item.eventId) continue;

      const items: any[] = this.getCartItemsWithProductId(this.products[i].productId);
      if (items.length === 0) continue;

      const offers = [...this.products[i].packageOffers];
      if (offers.length === 0) continue;

      for (let k = 0; k < offers.length; k += 1) {
        if (offers[k].dependencies.length > 0) continue;
        if (items.length < offers[k].size) return this.products[i];
      }
    }

    return null;
  }

  getItemType(item: any): string {    
    return item.data.product.productType;
  }

  getCartItemsOfType(type: string): any[] {
    const items: any[] = this.cartState.cartItems;
    const result: any[] = [];
    
    for (let i = 0; i < items.length; i++) {
      const t: string = items[i].product.productType;
      if (t != type) continue;
      
      result.push(items[i]);
    }
    
    return result;
  }

  getCartItems(type: string): any[] {
    const products: any[] = Object.assign([], this.cartState.cartItems);
    if (products.length === 0) {
      return [];
    } else {      
      const found: any[] = products.filter((r: any) => r.product ? r.product.productType === type : false);
      return found;
    }
  }

  getGroupedByEvent(items: any[]): any[] {
    const grouped: any[] = [];

    for (let item = 0; item < items.length; item++) {
      const eventId: string = items[item].catalog.catalogId;
      const eventName: string = items[item].catalog.title;

      if (eventId == "" || eventName == "") continue;

      if (grouped.length == 0) {          
        let i: any = {
          eventId: eventId,
          eventName: eventName,
          items: [],
        };
        i.items.push(items[item]);
        grouped.push(i);
        continue;
      } else {
        const index: number = grouped.findIndex((r: any) => 
          r.eventId == eventId
        );
        if (index >= 0) {
          grouped[index].items.push(items[item]);
        } else {
          let i: any = {
            eventId: eventId,
            eventName: eventName,
            items: [],
          };
          i.items.push(items[item]);
          grouped.push(i);
        }
      }
    }
    
    return grouped;    
  }

  isCountValid(value: string): boolean {
    if (value.length === 0) return false;
    const count = Number.parseInt(value, 10);
    if (count < 1 || count > 150) return false;
    if (Number.isFinite(count) && count.toString() === value) return true;
    return false;
  }

  getCurrentRecent(item: any) {
    let current;
    let eventRecentSearch: any = localStorage.getItem('eventRecentSearch');
    if (eventRecentSearch) {
      eventRecentSearch = JSON.parse(eventRecentSearch);
      if (item.runnerNum !== undefined) {
        current = eventRecentSearch.find((it: any) => {
          return it.event.eventId == item.eventId
            && it.search.startNumber == item.runnerNum
            && it.search.token;
        });
      } else {
        current = eventRecentSearch.find((it: any) => {
          return it.search.selfieId == item.selfieId
            && it.search.personId == item.personId
            && it.search.token;
        });
      }
    }
    return current;
  }

  async getGroupedItems(itemType: string): Promise<any[]> {
    if (itemType === 'number') {
      return await this.getGroupedByNumber();
    } else if (itemType === 'face') {
      return await this.getGroupedByFace();
    } else if (itemType === 'promocode') {
      return await this.getGroupedByPromocode();
    }
    return [];
  }

  getCartItemsWithProductId(productId: string): any[] {
    let result: any[] = [];

    const items: any[] = this.cartState.cartItems;
    if (items.length == 0) {
      return [];
    } 

    for (let i = 0; i < items.length; i++) {
      if (items[i].product.productId == productId) {
        result.push(items[i]);
      } 
    }
    return result;
  }

  getItemsOfProductType(items: any[], type: string = ''): any[] {
    const result: any[] = [];

    for (let i = 0; i < items.length; i++) {
      const t: string = items[i].data.product.productType;
      if (!type && !this.isItemGifted(items[i])) {
        result.push(items[i]);
      } else if (t === type && !this.isItemGifted(items[i])) {
        result.push(items[i]);
      }
    }
    
    return result;
  }

  getGiftsOfProductType(items: any[], type: string = ''): any[] {
    const result: any[] = [];

    for (let i = 0; i < items.length; i++) {
      const t: string = items[i].data.product.productType;
      if (!type && this.isItemGifted(items[i])) {
        result.push(items[i]);
      } else if (t === type && this.isItemGifted(items[i])) {
        result.push(items[i]);
      }
    }
    
    return result;
  }

  isItemGifted(item: any): boolean {
    if (!item) return false;
    if (!this.cartState.cart || !this.cartState.cart.candidates) return false;
    const items = this.cartState.cart.candidates.filter((i: any) => i.shoppingCartItemCandidateId === item.data.shoppingCartItemId);
    if (items.length > 0 && item.data.totalPrice === 0) return true;
    return false;
  }

  itemFrameNotInCart(item: any, product: any): boolean {
    const frames = this.getFramesInEvent(item, product.data);
    const framesInCart = this.getFramesInCart();
    const photoIds: any[] = product.photos.map((i: any) => { return i.photoId });

    for (let i = 0; i < frames.length; i += 1) {
      const inCart = framesInCart.filter((it: any) => it.product.productId === frames[i].productId);
      if (inCart.length > 0) {
        for (let k = 0; k < inCart.length; k += 1) {
          const type = inCart[k].product.productType;
          let ids: any[] = [];
          if (type === this.productTypes.frame) {
            ids = [ inCart[k].product.photo.photoId ];
          } else if (type === this.productTypes.photosWithFrame) {
            ids = [ ...inCart[k].product.photos.map((i: any) => { return i.photoId }) ];
          }
          if (this.areIdsMatch(photoIds, ids)) return false;
        }
      }
    }
    return frames.length > 0;
  }

  getFramesInEvent(item: any, productData: any): any[] {
    if (!item || !productData) return [];
    const found: any[] = this.frameProducts.filter((i: any) => i.catalog.catalogId === item.eventId);
    if (found.length > 0) {
      const product: any = this.products.find((i: any) => i.productId === productData.product.productId);
      const distance: any = product ? product.distance || "" : "";
      const productType: any = product ? product.productType || "" : "";
      let framesForDistance: any[] = [];
      if (productType === this.productTypes.photos_with_me) {
        framesForDistance = found.filter(
          (i: any) => i.distance ? i.distance === distance && i.productType === this.productTypes.photosWithFrame : i.productType === this.productTypes.photosWithFrame
        );
      } else if (productType === this.productTypes.photo) {
        if (item.runnerNum === '0') return [];
        framesForDistance = found.filter(
          (i: any) => i.distance ? i.distance === distance && i.productType === this.productTypes.frame : i.productType === this.productTypes.frame
        );
      }
      return framesForDistance;
    }
    return [];
  }

  areIdsMatch(photoIds: any[], ids: any[]): boolean {
    if (photoIds.length !== ids.length) return false;
    const a = new Set(photoIds);
    const b = new Set(ids);
    for (let value of a) {
      if (!b.has(value)) return false;
    }
    return true;
  }

  getFramesInCart(): any[] {
    const result = [];
    for (let i = 0; i < this.frameProducts.length; i += 1) {
      const items = this.getCartItemsWithProductId(this.frameProducts[i].productId);
      result.push(...items);
    }
    return result;
  }

  getItemsForCurrentSearch(itemsInCart: any[], item: any): any[] {
    if (item.runnerNum !== undefined) {
      return itemsInCart.filter((i: any) => Common.getAttribute(i.additionalAttributes, 'runnerNum') === item.runnerNum);
    }
    return itemsInCart.filter((i: any) => {
      Common.getAttribute(i.additionalAttributes, 'selfieId') === item.selfieId &&
      Common.getAttribute(i.additionalAttributes, 'personId') === item.personId
    });
  }

  async setPhotoFrameProps(item: any, productData: any): Promise<boolean> {
    if (!item || !productData) return false;

    const isPhotoWithMe = productData.product.productType === this.productTypes.photos_with_me;
    const isPhoto = productData.product.productType === this.productTypes.photo;

    this.photoFramesInCart = this.getFramesInCart();

    await this.setValuesForFrame(item.eventId, productData);
    const recent = this.getCurrentRecent(item);

    this.photoFrameProps = {
      eventId: item.eventId,
      eventName: item.eventName,
      runnerNum: item.runnerNum || '0',
      runnerFrameValues: this.runnerFrameValues,
      byFace: item.runnerNum && item.runnerNum !== '' ? false : true,
      faceImage: item.thumbnailUrl || '',
      personId: '',
      selfieId: '',
      photos: isPhoto ? [productData.product.photo] : productData.product.photos,
      frames: [],
      selectedPhoto: isPhoto ? productData.product.photo : productData.product.photos[0],
      selectedFrameProductId: '',
      autoShowPhotoId: '',
      photosWithFrame: null,
      allPhotosMode: isPhotoWithMe,
      gift: this.photoFrameGift,
      token: recent ? recent?.search.token || '' : '',
      searching: recent,
    };
    if (this.photoFrameProps.byFace) {
      this.photoFrameProps.personId = Common.getAttribute(productData.additionalAttributes, "personId");
      this.photoFrameProps.selfieId = Common.getAttribute(productData.additionalAttributes, "selfieId");
    }

    const found: any[] = this.frameProducts.filter((i: any) => i.catalog.catalogId === item.eventId);
    if (found.length > 0) {
      const product: any = this.products.find((i: any) => i.productId === productData.product.productId);
      const distance: any = product ? product.distance || "" : "";
      let frames: any[] = found.filter((i: any) => i.distance ? i.distance === distance : true);
      if (isPhoto) {
        frames = frames.filter((i: any) => i.productType === this.productTypes.frame);
      } else if (isPhotoWithMe) {
        frames = frames.filter((i: any) => i.productType === this.productTypes.photosWithFrame);
      } else {
        frames = [];
      }
      if (frames.length > 0) {
        this.photoFrameProps.frames = isPhoto ? frames : [frames[0]];
        this.photoFrameProps.selectedFrameProductId = frames[0].productId;
        this.photoFrameProps.photosWithFrame = isPhotoWithMe ? frames[0] : null;
      }
    }

    return true;
  }

  getValuesForFrame(): any[] {
    const values: any[] = [];
    
    if (this.photoFrameProps['runnerFrameValues']) {
      if (this.photoFrameProps['runnerFrameValues'].competitorId) {
        values.push({ key: 'CompetitorId', value: this.photoFrameProps['runnerFrameValues'].competitorId });
      }
    } else if (this.photoFrameProps['runnerNum']) {
      values.push({ key: 'ParticipantNumber', value: this.photoFrameProps['runnerNum'] !== "0" ? this.photoFrameProps['runnerNum'] : "-" });
    }

    return values;
  }

  getFrameAttributes(): any[] {
    const attributes: any[] = [];

    attributes.push({ key: "byFace", value: this.photoFrameProps['byFace'].toString() });

    if (!this.photoFrameProps['byFace']) {
      attributes.push({ key: "runnerNum", value: this.photoFrameProps['runnerNum'] || "0" });
      attributes.push({ key: "StartNumber", value: this.photoFrameProps['runnerNum'] || "0" });
    } else {
      attributes.push({ key: "selfieId", value: this.photoFrameProps['selfieId'] });
      attributes.push({ key: "personId", value: this.photoFrameProps['personId'] });
      attributes.push({ key: "PersonId", value: this.photoFrameProps['personId'] });
    }

    return attributes;
  }

  getPhotosWithMeAttributes(): any[] {
    const attributes: any[] = [];

    attributes.push({ key: "byFace", value: this.photoFrameProps['byFace'].toString() });

    if (!this.photoFrameProps['byFace']) {
      attributes.push({ key: "runnerNum", value:this.photoFrameProps['runnerNum'] || "0" });
      attributes.push({ key: "StartNumber", value: this.photoFrameProps['runnerNum'] || "0" });
    } else {
      attributes.push({ key: "selfieId", value: this.photoFrameProps['selfieId'] });
      attributes.push({ key: "personId", value: this.photoFrameProps['personId'] });
      attributes.push({ key: "PersonId", value: this.photoFrameProps['personId'] });
    }

    return attributes;
  }

  isInPhotosWithMe(product: any): boolean {
    const photosWithMe: any[] = this.getCartItemsOfType(this.productTypes.photos_with_me);
    if (photosWithMe.length == 0) return false;

    const photoId: string = product.product.photo.photoId;
    if (photoId.length == 0) return false;

    for (let i = 0; i < photosWithMe.length; i++) {
      const ids: string[] = photosWithMe[i].product.photos.map((r: any) => { return r.photoId });
      if (ids.length == 0) return false;

      if (ids.includes(photoId)) return true;
    }

    return false;
  }

  isInPhotosWithFrame(product: any): boolean {
    const photosWithFrame: any[] = this.getCartItemsOfType(this.productTypes.photosWithFrame);
    if (photosWithFrame.length === 0) return false;

    const photoId: string = product.product.photo.photoId;
    if (photoId.length === 0) return false;

    for (let i = 0; i < photosWithFrame.length; i++) {
      const ids: string[] = photosWithFrame[i].product.photos.map((r: any) => { return r.photoId });
      if (ids.length == 0) return false;

      if (ids.includes(photoId)) return true;
    }

    return false;
  }

  getPhotosForAttributes(): any[] {
    
    const selected = [];
    const photos = this.photoFrameProps.photos || [];

    for (let i = 0; i < photos.length; i++) {
      selected.push({ key: "PhotoId", value: photos[i].photoId });
    }

    return selected;
  }

  async setValuesForFrame(eventId: string, productData: any): Promise<void> {
    let runnerNum = '';
    if (this.photoFrameProps['byFace'] === true) {
      runnerNum = '';
    } else {
      runnerNum = Common.getAttribute(productData.additionalAttributes, "runnerNum");
    }

    if (!runnerNum) return;
    
    const payload: any = { eventId, search: runnerNum };
    await this.searchState.clearFound();
    await this.searchState.searchQuick(payload);

    const runners: any[] = this.searchState.runners.filter((i: any) => i.number === runnerNum);
    if (runners.length == 0 || runners.length > 1) return;

    const value = runners[0];
    const runnerFrameValues: any = {
      competitorId: value.competitorId || "",
    };
    if (value.surname) runnerFrameValues["surname"] = value.surname;
    if (value.firstName) runnerFrameValues["firstName"] = value.firstName;
    if (value.time) runnerFrameValues["time"] = value.time;
    if (value.position) runnerFrameValues["position"] = value.position.toString();
    if (value.distance) runnerFrameValues["distance"] = value.distance;
    this.runnerFrameValues = runnerFrameValues;
  }

  async getGroupedByNumber(): Promise<any[]> {
    const items: any[] = await this.getByNumberItems();
    let grouped: any[] = [];

    for (let item = 0; item < items.length; item++) {
      const eventId: string = items[item].data.catalog.catalogId;
      const eventName: string = items[item].data.catalog.title;
      const runnerNum: string = Common.getAttribute(items[item].data.additionalAttributes, "runnerNum");

      if (eventId == "" || eventName == "" || runnerNum == "") continue;

      if (grouped.length == 0) {          
        let i: any = { eventId: eventId, eventName: eventName, runnerNum: runnerNum, items: [] };
        i.items.push({ ...items[item] });
        grouped.push(i);
        continue;
      } else {
        const index: number = grouped.findIndex((r: any) => 
          r.eventId == eventId && r.runnerNum == runnerNum
        );
        if (index >= 0) {
          grouped[index].items.push({ ...items[item] });
        } else {
          let i: any = { eventId: eventId, eventName: eventName, runnerNum: runnerNum, items: [] };
          i.items.push({ ...items[item] });
          grouped.push(i);
        }
      }
    }
    
    return grouped;
  }

  async getGroupedByFace(): Promise<any[]> {
    const items: any[] = await this.getByFaceItems();
    let grouped: any[] = [];

    for (let item = 0; item < items.length; item++) {
      const eventId: string = items[item].data.catalog.catalogId;
      const eventName: string = items[item].data.catalog.title;
      const selfieId: string = Common.getAttribute(items[item].data.additionalAttributes, "selfieId");
      const personId: string = Common.getAttribute(items[item].data.additionalAttributes, "personId");

      if (eventId == "" || eventName == "" || selfieId == "" || personId == "") continue;

      const thumbnailUrl: string = await this.getFaceThumbnail(selfieId, personId);

      if (grouped.length == 0) {          
        let i: any = { eventId: eventId, eventName: eventName, 
          selfieId: selfieId, personId: personId, thumbnailUrl: thumbnailUrl, items: [] 
        };
        i.items.push(items[item]);
        grouped.push(i);
        continue;
      } else {
        const index: number = grouped.findIndex((r: any) => 
          r.eventId == eventId && r.selfieId == selfieId
        );
        if (index >= 0) {
          grouped[index].items.push(items[item]);
        } else {
          let i: any = { eventId: eventId, eventName: eventName, 
            selfieId: selfieId, personId: personId, thumbnailUrl: thumbnailUrl, items: [] 
          };
          i.items.push(items[item]);
          grouped.push(i);
        }
      }
    }
    
    return grouped;
  }

  async getGroupedByPromocode(): Promise<any[]> {
    const items: any[] = (await this.getCartItemsOfType(this.productTypes.promocode)).map((item: any) => {
      return { data: item };
    });
    let grouped: any[] = [];

    for (let item = 0; item < items.length; item++) {
      const eventId: string = items[item].data.catalog.catalogId;
      const eventName: string = items[item].data.catalog.title;

      if (eventId == "" || eventName == "") continue;

      if (grouped.length == 0) {          
        let i: any = {
          eventId: eventId,
          eventName: eventName,
          items: [],
          count: '1',
          originalPrice: items[item].data.originalPrice,
          totalPrice: items[item].data.totalPrice,
          updating: false,
        };
        i.items.push(items[item]);
        grouped.push(i);
        continue;
      } else {
        const index: number = grouped.findIndex((r: any) => 
          r.eventId == eventId
        );
        if (index >= 0) {
          grouped[index].items.push(items[item]);
          grouped[index].count = grouped[index].items.length.toString();
          grouped[index].originalPrice += items[item].data.originalPrice;
          grouped[index].totalPrice += items[item].data.totalPrice;
        } else {
          let i: any = {
            eventId: eventId,
            eventName: eventName,
            items: [],
            count: '1',
            originalPrice: items[item].data.originalPrice,
            totalPrice: items[item].data.totalPrice,
            updating: false,
          };
          i.items.push(items[item]);
          grouped.push(i);
        }
      }
    }
    
    return grouped;
  }

  async updatePromocodeAmount(item: any): Promise<void> {
    if (item.count.length === 0) return;    
    this.debouncedUpdatePromocodes(item);
  }

  async updatePromocodes(item: any): Promise<void> {
    const count = Number.parseInt(item.count, 10);
    if (count < item.items.length) {
      item.updating = true;
      await this.onDeleteProductsById(item.items.slice(0, item.items.length - count));
      item.updating = false;
    } else if (count > item.items.length) {
      await this.addPromocodes(item, count - item.items.length);
    }   
  }
  
  async addPromocodes(item: any, count: number): Promise<void> {
    if (this.isReloading) return;
    this.isReloading = true;

    const payload = { 
      cartId: this.cartState.cartId,
      data: {
        catalogId: item.eventId,
        productId: item.items[0].data.product.productId,
        items: new Array(),
      }
    };

    for (let i = 0; i < count; i += 1) {
      const item: any = {
        attributes: [{ key: "PromocodeId", value: uuidv4() }],
        additionalAttributes: [],
      };
      payload.data.items.push(item);
    }

    await this.cartState.addItems(payload);
    if (this.cartState.isCartError) {
      if (this.cartState.responseStatus == 409) {
        this.displayError(this.$t('searchResults/errorAlreadyAdded').toString());
      } else {
        this.displayError(this.$t('searchResults/errorAddingProduct').toString() + " " + this.cartState.responseStatus);
      }
      this.isReloading = false;
      return;
    }

    await this.cartState.getCart({ cartId: this.cartState.cartId });
    await this.updateGroupedItems();

    this.isReloading = false;
  }

  async getByNumberItems(): Promise<any[]> {
    return await this.getItemsOfSearchType(false);
  }

  async getByFaceItems(): Promise<any[]> {
    return await this.getItemsOfSearchType(true);
  }

  async getItemsOfSearchType(byFace: boolean): Promise<any[]> {
    let result: any[] = [];

    const items: any[] = [ ...this.cartState.cartItems ];
    for (let i = 0; i < items.length; i++) {
      if (items[i].additionalAttributes) {
        const searchType: string = Common.getAttribute(items[i].additionalAttributes, 'byFace');
        if (!searchType) continue;
        
        if (searchType === byFace.toString()) {
          const photos: any[] = await this.getPhotosItemPhotos(items[i]);
          result.push({ data: items[i], photos: photos });
        }
      } 
    }
    
    return result;
  }

  getImageSrc(item: any): string {
    if (!item || !item.product) return "";

    return item.product.photo.resources.preview;
  }

  async getFaceThumbnail(selfieId: string, personId: string): Promise<string> {
    if (selfieId.length == 0 || personId.length == 0) return "";

    await this.searchState.loadBySelfieId({ selfieId: selfieId });
    
    const found: any[] = this.searchState.persons.filter((r: any) => r.personId == personId);
    if (found.length == 0) return "";
    return found[0].faceUrl;
  }

  isDiscountedPrice(product: any): boolean {
    return product.originalPrice != product.totalPrice;
  }

  isTwoRowsPrice(product: any): boolean {
    return this.isDiscountedPrice(product) && this.lang !== 'ru' && (this.smOnly || this.mdOnly);
  }

  getByNumberDeletingItem(shoppingCartItemId: string): any {
    for (let i = 0; i < this.byNumber.length; i += 1) {
      const found: any[] = this.byNumber[i].items.filter((r: any) => r.data.shoppingCartItemId === shoppingCartItemId);
      if (found.length > 0) {
        return { ...this.byNumber[i] };
      }
    }
    
    return null;
  }

  getByFaceDeletingItem(shoppingCartItemId: string): any {
    for (let i = 0; i < this.byFace.length; i += 1) {
      const found: any[] = this.byFace[i].items.filter((r: any) => r.data.shoppingCartItemId === shoppingCartItemId);
      if (found.length > 0) {
        return { ...this.byFace[i] };
      }
    }
    
    return null;
  }

  updateRemovedItems(shoppingCartItemId: string, removingCount: number): boolean {
    const itemByNumber: any = this.getByNumberDeletingItem(shoppingCartItemId);
    const itemByFace: any = this.getByFaceDeletingItem(shoppingCartItemId);

    if (itemByNumber && itemByNumber.items.length === removingCount) {
      this.byNumberRemoved.push({ 
        eventId: itemByNumber.eventId, 
        eventName: itemByNumber.eventName, 
        byFace: 'false',
        runnerNum: itemByNumber.runnerNum, 
        items: [],
      });
      return true;
    } else if (itemByFace && itemByFace.items.length === removingCount) {
      this.byFaceRemoved.push({ 
        eventId: itemByFace.eventId, 
        eventName: itemByFace.eventName, 
        byFace: 'true',
        selfieId: itemByFace.selfieId,
        personId: itemByFace.personId,
        thumbnailUrl: itemByFace.thumbnailUrl,
        items: [],
      });
      return true;
    }

    return false;
  }

  async getPhotosItemPhotos(item: any): Promise<any[]> {
    
    const type: string = item.product.productType;

    let photos: any[] = [];
    if (type === this.productTypes.photo || type === this.productTypes.frame) {
      photos = [ { ...item.product.photo } ];  
    } else if (type === this.productTypes.photos_with_me || type === this.productTypes.photosWithFrame) {
      photos = [ ...item.product.photos ];
    }
    
    return photos;
  }

  @Watch("promocode")
  async onPromocodeChanged(): Promise<void> {
    this.promocodeError = "";
  }

  @Watch("cartItemsCount")
  async onItemsCountChanged(): Promise<void> {
    if (this.loading) return;
    this.products = [];
    await this.loadProducts();
    await this.updateGroupedItems();
  }

  @Watch("loading")
  async onShow(): Promise<void> {
    setTimeout(() => { this.settingsState.setLoadingRoute(this.loading) }, 1000);
  }

  async onStartSearch(): Promise<void> {
    this.$store.state.history = [];
    await this.$router.push({ 
      name: "home",
      params: { 
        startSearch: "true",
      }
    });
  }

  async onContinue(item: any): Promise<void> {
    if (this.isReloading) return;
    if (item.items.length == 0) return;

    if (typeof item.updating === 'boolean') {
      await this.eventsState.getEventById(item.eventId);
      this.$router.push({ 
        name: "event",
        params: { 
          id: this.eventsState.event.externalEventId,
        },
      }).catch(() => {});
      return;
    }

    const byFace: boolean = "true" === Common.getAttribute(item.items[0].data.additionalAttributes, "byFace");

    if (!byFace && item.runnerNum === "0") {
      await this.eventsState.getEventById(item.eventId);
      await this.showAllPhotos();
      return;
    }

    await this.eventsState.getEventById(item.eventId);
    if (this.eventsState.event === null) return;

    this.$store.state.history = ["home"];

    let num: string = "";
    if (byFace) {
      num = item.selfieId + "+" + item.personId;
    } else {
      num = item.runnerNum;
    }

    this.$router.push({ 
      name: "search-from-url",
      params: { 
        id: this.eventsState.event.externalEventId,
        number: num,
      } 
    }).catch(() => {});
  }

  async onContinueRemoved(item: any): Promise<void> {
    if (this.isReloading) return;

    const byFace: boolean = "true" === item.byFace;

    if (!byFace && item.runnerNum === "0") {
      await this.eventsState.getEventById(item.eventId);
      await this.showAllPhotos();
      return;
    }
    
    await this.eventsState.getEventById(item.eventId);
    if (this.eventsState.event === null) return;

    this.$store.state.history = ["home"];

    let num: string = "";
    if (byFace) {
      num = item.selfieId + "+" + item.personId;
    } else {
      num = item.runnerNum;
    }

    this.$router.push({ 
      name: "search-from-url",
      params: { 
        id: this.eventsState.event.externalEventId,
        number: num,
      } 
    }).catch(() => {});
  }

  async onPhotoSelected(item: any, index: number): Promise<void> {
    this.photos = await this.getPhotosItemPhotos(item);
    this.photosIndex = index;
    this.photosDialog = true;
  }

  async onAddPhotosWithMe(item: any, productData: any): Promise<void> {
    if (!item || !productData) return;

    if (this.isAddingPhotosWithMe) return;

    this.isAddingPhotosWithMe = true;

    const recent = this.getCurrentRecent(item);
    
    const attributes = await this.getPhotosForAttributes();
    if (attributes.length === 0) {
      this.displayError(this.$t('searchResults/errorAddingProduct').toString());
      this.isAddingPhotosWithMe = false;
      return;
    }
    const payload = { 
      cartId: this.cartState.cartId,
      searchToken: recent ? recent?.search.token || '' : '',
      item: {
        catalogId: item.eventId,
        productId: productData.product.productId,
        attributes: attributes,
        additionalAttributes: this.getPhotosWithMeAttributes(),
      }
    };
    
    await this.cartState.addItem(payload);
    if (this.cartState.isCartError) {
      this.displayError(this.$t('searchResults/errorAddingProduct').toString() + " " + this.cartState.responseStatus);
      this.isAddingPhotosWithMe = false;
      return;
    }
    
    await this.cartState.setCartAttributes({ cartId: this.cartState.cartId });
    await this.cartState.getCart({ cartId: this.cartState.cartId });
    await this.cartState.pushEcommerceAdd();
    this.isAddingPhotosWithMe = false;
  }

  async onAddFrame(item: any, productData: any, gift: any = null): Promise<void> {
    if (!item || !productData) return;

    const giftIsFrame = gift && gift.product.productType === this.productTypes.frame;
    const giftSize = gift && gift.size || 0;
    const giftQuantity = gift && gift.quantity || 0;
    const isPhotoWithMe = productData.product.productType === this.productTypes.photos_with_me;
    const isPhoto = productData.product.productType === this.productTypes.photo;
    const allPhotosMode = isPhotoWithMe && !giftIsFrame;

    let photoFramesInCart: any[] = this.getFramesInCart();
    this.photoFramesInCart = this.getItemsForCurrentSearch(photoFramesInCart, item);
    this.photoFrameGift = gift;

    await this.setValuesForFrame(item.eventId, productData);
    const recent = this.getCurrentRecent(item);

    this.photoFrameProps = {
      eventId: item.eventId,
      eventName: item.eventName,
      runnerNum: item.runnerNum || '0',
      runnerFrameValues: this.runnerFrameValues,
      byFace: item.runnerNum && item.runnerNum !== '' ? false : true,
      faceImage: item.thumbnailUrl || '',
      personId: '',
      selfieId: '',
      photos: isPhoto ? [productData.product.photo] : productData.product.photos,
      frames: [],
      selectedPhoto: isPhoto ? productData.product.photo : productData.product.photos[0],
      selectedFrameProductId: '',
      autoShowPhotoId: '',
      photosWithFrame: null,
      allPhotosMode,
      gift: this.photoFrameGift,
      token: recent ? recent?.search.token || '' : '',
      searching: recent,
    };
    if (this.photoFrameProps.byFace) {
      this.photoFrameProps.personId = Common.getAttribute(productData.additionalAttributes, 'personId');
      this.photoFrameProps.selfieId = Common.getAttribute(productData.additionalAttributes, 'selfieId');
    }

    const found: any[] = this.frameProducts.filter((i: any) => i.catalog.catalogId === item.eventId);
    if (found.length > 0) {
      const product: any = this.products.find((i: any) => i.productId === productData.product.productId);
      const distance: any = product ? product.distance || '' : '';
      let frames: any[] = found.filter((i: any) => i.distance ? i.distance === distance : true);
      if (isPhoto || (isPhotoWithMe && giftIsFrame)) {
        frames = frames.filter((i: any) => i.productType === this.productTypes.frame);
      } else if (isPhotoWithMe && !giftIsFrame) {
        frames = frames.filter((i: any) => i.productType === this.productTypes.photosWithFrame);
      } else {
        frames = [];
      }
      if (frames.length > 0) {
        this.photoFrameProps.frames = isPhoto || (isPhotoWithMe && giftIsFrame) ? frames : [frames[0]];
        this.photoFrameProps.selectedFrameProductId = frames[0].productId;
        this.photoFrameProps.photosWithFrame = isPhotoWithMe && !giftIsFrame ? frames[0] : null;

        if (isPhoto && giftIsFrame && giftSize > 0 && giftQuantity > 1) {
          const items = this.getItemsOfProductType(item.items, this.productTypes.photo).slice(0, giftQuantity);
          const photos = [];
          for (let i = 0; i < items.length; i += 1) {
            photos.push(...items[i].photos);
          }
          this.photoFrameProps.photos = [...photos];
        }

        this.photoFrameDialog = true;
      }
    }
  }

  async addFrameToCart(params: {
    selectedPhoto: string,
    selectedPhotoUrl: string,
    selectedFrameId: string,
    selectedFrameProductId: string,
  }): Promise<void> {
    if (this.isAddingFrame) return;

    this.isAddingFrame = true;
    
    const attributes: any[] = [
      { key: "PhotoId", value: params.selectedPhoto },
      { key: "FrameId", value: params.selectedFrameId },
    ];

    const payload = { 
      cartId: this.cartState.cartId,
      searchToken: this.photoFrameProps ? this.photoFrameProps.token : '',
      item: {
        catalogId: this.photoFrameProps.eventId,
        productId: params.selectedFrameProductId,
        attributes: [
          ...attributes,
          ...this.getValuesForFrame(),
        ],
        additionalAttributes: this.getFrameAttributes(),
      }
    };
    
    await this.cartState.addItem(payload);
    if (this.cartState.isCartError) {
      if (this.cartState.responseStatus == 409) {
        this.displayError(this.$t('searchResults/errorAlreadyAdded').toString());
      } else {
        this.displayError(this.$t('searchResults/errorAddingProduct').toString() + " " + this.cartState.responseStatus);
      }
      this.isAddingFrame = false;
      return;
    }

    await this.cartState.setCartAttributes({ cartId: this.cartState.cartId });
    await this.cartState.getCart({ cartId: this.cartState.cartId });
    await this.cartState.pushEcommerceAdd();
    this.photoFramesInCart = this.getFramesInCart();
    this.isAddingFrame = false;
  }

  async onPhotoFrameAdd(payload: {
    selectedPhoto: string,
    selectedPhotoUrl: string,
    selectedFrameId: string,
    selectedFrameProductId: string,
  }): Promise<void> {
    if (payload.selectedPhoto !== undefined) {
      if (payload.selectedFrameId !== undefined) {
        await this.addFrameToCart(payload);
        if (this.cartState.isCartError) return;
      }
    }
  }

  async onPhotoFrameRemove(cartItemId: string): Promise<void> {
    this.isAddingFrame = true;

    const payload = { 
      cartId: this.cartState.cartId,
      cartItemId,
    };
    
    await this.cartState.removeItem(payload);
    if (this.cartState.isCartError) {
      this.displayError(this.$t('searchResults/errorAddingProduct').toString() + " " + this.cartState.responseStatus);
      this.isAddingFrame = false;
      return;
    }

    await this.cartState.getCart({ cartId: this.cartState.cartId });
    this.photoFramesInCart = this.getFramesInCart();
    this.isAddingFrame = false;
  }

  async onPhotosWithFrameAdd(): Promise<void> {
    if (this.isAddingPhotosWithFrame) return;

    this.isAddingPhotosWithFrame = true;
    
    const frameId = Common.getAttribute(this.photoFrameProps.photosWithFrame.attributes, 'FrameId');
    const attributes: any[] = [
      ...this.getPhotosForAttributes(),
      { key: "FrameId", value: frameId },
      ...this.getValuesForFrame(),
    ];

    const payload = { 
      cartId: this.cartState.cartId,
      searchToken: this.photoFrameProps ? this.photoFrameProps.token : '',
      item: {
        catalogId: this.photoFrameProps.eventId,
        productId: this.photoFrameProps.photosWithFrame.productId,
        attributes,
        additionalAttributes: this.getFrameAttributes(),
      }
    };
    
    await this.cartState.addItem(payload);
    if (this.cartState.isCartError) {
      if (this.cartState.responseStatus == 409) {
        this.displayError(this.$t('searchResults/errorAlreadyAdded').toString());
      } else {
        this.displayError(this.$t('searchResults/errorAddingProduct').toString() + " " + this.cartState.responseStatus);
      }
      this.isAddingPhotosWithFrame = false;
      return;
    }

    await this.cartState.setCartAttributes({ cartId: this.cartState.cartId });
    await this.cartState.getCart({ cartId: this.cartState.cartId });
    
    this.isAddingPhotosWithFrame = false;
    this.photoFrameDialog = false;
  }

  async onPhotoFrameClosed(): Promise<void> {
    this.photoFrameDialog = false;
    this.photoFrameProps = {};
  }

  async onAddProduct(item: any, isDiscount: boolean = false): Promise<void> {
    if (!item) return;

    let product = null;
    if (!isDiscount) product = this.getOfferProduct(item);
    else product = this.getDiscountProduct(item.eventId);
    if (!product) return;

    const type = product.productType;
    if (type === this.productTypes.photos_with_me) {
      await this.onContinue(item);
    } else if (type === this.productTypes.photosWithFrame) {
      await this.onContinue(item);
    } else if (type === this.productTypes.photo) {
      await this.onContinue(item);
    } else if (type === this.productTypes.frame) {
      await this.onContinue(item);
    }
  }

  async onAddGift(item: any, productData: any): Promise<void> {
    if (!item || !productData) return;

    const gift = this.getGift(productData);
    if (!gift) return;

    const triggerType = gift.trigger.productType;
    const giftType = gift.product.productType;
    if (!triggerType || !giftType) return;

    if (triggerType === this.productTypes.photos_with_me) {
      if (giftType === this.productTypes.photosWithFrame) {
        if (await this.setPhotoFrameProps(item, productData)) {
          await this.onPhotosWithFrameAdd();
        }
        return;
      }
      if (giftType === this.productTypes.frame) {
        await this.onAddFrame(item, productData, gift);
        return;
      }
    } else if (triggerType === this.productTypes.photosWithFrame) {
      if (giftType === this.productTypes.photos_with_me) {
        if (await this.setPhotoFrameProps(item, productData)) {
          await this.onAddPhotosWithMe(item, gift);
        }
        return;
      }
    } else if (triggerType === this.productTypes.photo) {
      if (giftType === this.productTypes.frame) {
        await this.onAddFrame(item, productData, gift);
        return;
      }
    }

    await this.onContinue(item);
  }

  async onClearCart(): Promise<void> {
    if (this.isReloading) return;
    await this.cartState.clearCart({ cartId: this.cartState.cartId });
    await this.cartState.pushEcommerceRemove([]);
    
    this.$store.state.history = [];
    await this.$router.push({ name: "home" });
  }

  async onDeleteProduct(product: any): Promise<void> {
    this.isReloading = true;

    this.updateRemovedItems(product.shoppingCartItemId, 1);

    await this.cartState.removeItem({ cartId: this.cartState.cartId, cartItemId: product.shoppingCartItemId });
    await this.cartState.pushEcommerceRemove([product.shoppingCartItemId]);
    await this.cartState.getCart({ cartId: this.cartState.cartId });
    await this.updateGroupedItems();    

    this.isReloading = false;
  }

  async onDeleteProductsById(products: any[]): Promise<void> {
    this.isReloading = true;
    
    const items: string[] = [];
    for (let i = 0; i < products.length; i++) {
      items.push(products[i].data.shoppingCartItemId);
    }

    this.updateRemovedItems(products[0].data.shoppingCartItemId, products.length);

    await this.cartState.removeItemsById({ cartId: this.cartState.cartId, items });
    await this.cartState.pushEcommerceRemove(items);
    await this.cartState.getCart({ cartId: this.cartState.cartId });
    await this.updateGroupedItems();

    this.isReloading = false;
  }

  async onApplyPromocode(): Promise<void> {
    if (this.promocode.length == 0) return;

    this.settingsState.reachGoal('use_promocode');

    await this.cartState.addPromocode({ cartId: this.cartState.cartId, code: this.promocode });
    if (this.cartState.isCartError) {
      if (this.cartState.cartErrorData !== null && this.cartState.cartErrorData.code !== undefined) {

        const code: string = this.cartState.cartErrorData.code;
        if (code == PromocodeErrors.notFound) {
          this.promocodeError = this.$t('promocode/errorNotFound').toString();
          return;
        } else if (code == PromocodeErrors.expired) {
          this.promocodeError = this.$t('promocode/errorExpired').toString();
          return;
        } else if (code == PromocodeErrors.inactive) {
          this.promocodeError = this.$t('promocode/errorInactive').toString();
          return;
        } else if (code == PromocodeErrors.conflict) {
          this.promocodeError = this.$t('promocode/errorConflict').toString();
          return;
        } else if (code == PromocodeErrors.limit) {
          this.promocodeError = this.$t('promocode/errorLimit').toString();
          return;
        } else if (code == PromocodeErrors.restricted) {
          this.promocodeError = this.$t('promocode/errorRestricted').toString();
          return;
        } else {
          this.promocodeError = this.$t('promocode/errorUnknown').toString() + ": " + code;
          return;
        }
      } else {
        this.promocodeError = this.$t('promocode/errorUnknown').toString();
        return;
      }
    }
    this.promocodeError = "";
    this.promocode = "";
    
    this.reloadCount = 0;
    this.reloadCart();
  }

  async onRemovePromocode(codeId: string): Promise<void> {
    await this.cartState.removePromocode({ cartId: this.cartState.cartId, codeId: codeId });
    this.reloadCount = 0;
    this.reloadCart();
  }

  async onSubmit(event: Event): Promise<void> {
    event.preventDefault();
  }

  async onKeyDown(item: any, event: any): Promise<void> {
    if (['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight'].includes(event.key)) {
      return;
    }
    if (event.key === 'Enter' && this.isCountValid(item.count)) {
      event.preventDefault();
      this.updatePromocodeAmount(item);
      return;
    }
    if (event.key === 'ArrowUp') {
      event.preventDefault();
      setTimeout(() => { this.onChangeValue(item, 1); }, 100);
      return;
    }
    if (event.key === 'ArrowDown') {
      event.preventDefault();
      setTimeout(() => { this.onChangeValue(item, -1); }, 100);
      return;
    }
    if (item.count.length === 0 && event.key === '0') {
      event.preventDefault();
      return;
    }
    const template = /[0-9]/;
    if (!template.test(event.key) || item.count.length === 3) {
      event.preventDefault();
      return;
    }
    if (Number.parseInt(`${item.count}${event.key}`, 10) > 150) {
      event.preventDefault();
      return;
    }
    setTimeout(() => { this.onChangeValue(item, 0); }, 100);
  }

  async onBlur(item: any): Promise<void> {
    if (this.isCountValid(item.count)) {
      this.updatePromocodeAmount(item);
    }    
  }

  async onChangeValue(item: any, add: number): Promise<void> {
    if (item.count.length === 0) {
      item.count = '1';
      this.updatePromocodeAmount(item);
      return;
    }
    let count = Number.parseInt(item.count, 10);
    if ((count + add) < 0) {
      await this.onDeleteProductsById(item.items);
      return;
    }
    if (count + add > 150) return;
    item.count = (count + add).toString();
    if (add !== 0) this.updatePromocodeAmount(item);
  }

  async showAllPhotos(): Promise<void> {
    if (this.$route.name === "event-photos") return;

    const event: any = this.eventsState.event;

    await this.$router.push({
      name: "event-photos",
      params: {
        id: event.externalEventId,
      }
    });
  }

  async reloadCart(): Promise<void> {
    this.isReloading = true;
    await this.cartState.getCart({ cartId: this.cartState.cartId });
    await this.updateGroupedItems();

    if (this.cartState.isCartError) {
      this.isReloading = false;
      this.reloadCount = 0;
      return;
    }

    if (this.cartState.isUpdated) {
      this.isReloading = false;
      this.reloadCount = 0;
      return;
    }

    if (this.cartItemsCount == 0) {
      this.isReloading = false;
      this.reloadCount = 0;
      await this.$router.push({ name: "home" });
      return;
    }

    this.reloadCount++;
    if (this.reloadCount < this.max_reload_count) {
      if (this.$route.name == "cart") {
        setTimeout(async () => this.reloadCart(), 1000);
      }
    } else {
      this.isReloading = false;
      this.reloadCount = 0;
    }
  }

  async onGoToOrder(): Promise<void> {
    if (this.isReloading) return;

    this.$store.state.history.push("cart");

    await this.$router.push("order");
  }

  async loadProducts(): Promise<void> {
    const items: any[] = [ ...this.cartState.cartItems ];
    
    for (let i = 0; i < items.length; i += 1) {
      const found: any[] = this.products.filter((r: any) => r.productId == items[i].product.productId);
      if (found.length > 0) continue;
      
      await this.catalogState.getProduct({ productId: items[i].product.productId });

      if (this.catalogState.product) {
        const product: any = { ...this.catalogState.product };
        product["productId"] = items[i].product.productId;
        product["type"] = items[i].product.productType;
        
        this.products.push(product);
      }
    }
  }

  async loadAdditionalFrameProducts(): Promise<void> {
    const items: any[] = [ ...this.products ];
    
    for (let i = 0; i < items.length; i += 1) {
      if (items[i].type !== this.productTypes.photo && items[i].type !== this.productTypes.photos_with_me) continue;
      
      const distance: string = items[i].distance || "";
      await this.catalogState.getProducts({ eventId: items[i].catalog.catalogId, distance });
      
      const frames: any[] = [...this.catalogState.products].filter(
        (item: any) => item.productType === this.productTypes.frame || item.productType === this.productTypes.photosWithFrame
      );
      for (let k = 0; k < frames.length; k += 1) {
        if (!this.frameProducts.find((item: any) => item.productId === frames[k].productId)) {
          this.frameProducts.push(frames[k]);
        }
      }
    }
  }

  async updateGroupedItems(): Promise<void> {
    this.byNumber_ = await this.getGroupedItems('number');
    this.byFace_ = await this.getGroupedItems('face');
    this.promocodeItems = await this.getGroupedItems('promocode');
  }

  async mounted(): Promise<void> {
    this.$store.state.buttonHome = true;
    this.$store.state.buttonBack = false;
    this.$store.state.buttonCart = true;
    this.$store.state.showMenu = true;

    await this.settingsState.setAppBarHidden(false);
    await this.settingsState.setLoadingRoute(true);

    await this.cartState.loadCartId();
    
    if (this.cartState.cartId != "") {
      
      await this.cartState.getCart({ cartId: this.cartState.cartId });
      
      if (this.cartState.isCartError) {
        await this.cartState.removeCart();
      }
    }

    if (this.cartState.cartId != "") {
      if (this.cartState.isCheckoutDone) {
        await this.settingsState.setMyPhotosLink("");
        await this.$router.push({ name: "order" });
        return;
      } 
    }

    if (this.cartState.itemsCount > 0) {
      await this.loadProducts();
      await this.loadAdditionalFrameProducts();
      await this.updateGroupedItems();
      this.debouncedUpdatePromocodes = Debounce(this.updatePromocodes, 500);
    }
    
    this.$store.state.goBack = "";
    this.$store.state.goPage = "";
    this.backToTop();

    await this.settingsState.setMyPhotosLink("");

    this.loading = false;

    this.settingsState.trackPage("cart");
  }

}
